import React from "react";
import "../../Styles/error.css";

const Home = () => {

    return (
        <div className="error-page">
            <div className="error-container">
                <h1>404</h1>
                <p>Page Not Found</p>
            </div>
        </div>
    );
}

export default Home;